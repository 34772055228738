.category__ul {
    height: 100%;
    margin-bottom: 1rem;
}

.category__heading {
    background: $color-mid;
    display: flex;
    justify-content: space-between;
    padding: .5rem 1rem;
    align-items: center;

    & > * {
        pointer-events: none;
    }
}

.items__ul {
    height: 100%;
    overflow: hidden;
    transition: all .2s ease;
}
