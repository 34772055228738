.items__ul {
    padding: 0;
}

.items__li {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $color-mid;
    // height: 100%;
    
    &:not(:first-child) {
        margin-top: -1px;
    }

    &:first-child {
        border-radius: 3px 3px 0 0;
    }

    &:last-child {
        border-radius: 0 0 3px 3px;
    }

    
    
}

.items__left {
    width: 70%;

    label {
        padding: 2rem 0;
        display: inline-block;
        // width: 90%;
    }

    input {
        margin-left: 1rem;
    }

    pointer-events: none;
}

.items__right {
    display: flex;
    & > * {
        margin-right: .5rem;
    }
}

.item__remove {
    background-color: $color-remove;
    padding: 1rem 1.5rem;
    border-radius: 3px;
}

.item__rename {
    background-color: $color-rename;
    padding: 1rem 1.5rem;
    border-radius: 3px;
}

.item__save {
    background-color: $color-rename;
    padding: 1rem 1.5rem;
    border-radius: 3px;
}

.item__cancel {
    background-color: $color-remove;
    padding: 1rem 1.5rem;
    border-radius: 3px;
}

.input__change {
    border: none;
    border-bottom: 1px solid $color-rename;
    width: 70%;
    outline: none;
}

