.relative {
    position: relative;
}

.bought {
    margin: 0 auto;
    width: 80%;

    background-color: lighten($color-mid, 20%);
    box-shadow: 2px 2px 8px rgba($color-dark, .3);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    height: 80vh;
    padding: 1rem;
    border-radius: 2px;
    z-index: 9999;

    .icon_cross {
        position: absolute;
        top: -1.25rem;
        right: -1.25rem;
        width: 2.5rem;
        height: 2.5rem;
        background-color: $color-mid;
        padding: .5rem;
        border-radius: 50%;
        fill: $color-dark;
        cursor: pointer;
    
        & > * {
            pointer-events: none;
        }

    }


}

.bought__list {
    background-color: $color-light;
    height: 100%;
    padding: 1rem;
    overflow-y:scroll;

}

.bought__item {
    padding: 1.5rem 2rem;
}

.hideBought {
    display: none;
}

.showBought {
    padding: 1.5rem 2.5rem;
    background-color: $color-rename;
    color: $color-light;
    display: inline-block;
    cursor: pointer;
    position: absolute;
    top: 3rem;
    left: 3rem;
    z-index: 999;
    box-shadow: 2px 2px 5px rgba($color-dark, .5);


    @include respond(tab-port) {
        position: fixed;
        top: auto;
        bottom: 3rem;
    }
}



