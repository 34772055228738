.categories__ul {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;
    position: relative;
    flex-wrap: wrap;

    @include respond(tab-land) {
        justify-content: flex-start;
    }
}

.categories__li {
    list-style: none;
    margin-bottom: .5rem;

    &:not(:last-child) {
        margin-right: 1rem;
    }

    & > .btn > * {
        pointer-events: all;
    }
}


.categories__btn {
    padding: .5rem 1rem;
    background-color: $color-add;
    border-radius: 3px;
}


.faded {
    background-color: rgba($color-add, .5);
}