* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;;
}

a {
    text-decoration: none;
}

html, body {
    margin: 0;
    padding: 0;
    font-size: 62.5%;
}

body {
    font-size: 1.6rem;
}



.container {
    max-width: 1170px;
    padding: 0 1.5rem;
    margin: 0 auto;
}

.container.app {
    margin-top: 5rem;
}

.btn {
    display: inline-block;
    color: $color-light;
    outline: none;
    border: none;
    cursor: pointer;

    & > * {
        pointer-events: none;
    }

}

.fav .btn__star {
    pointer-events: none;

    svg {
        fill: gold;
    }
}


.form__btn {
    background-color: $color-add;
    padding: 0 1rem;
    border-radius: 0 5px 5px 0 ;
}

.wrapper {
    width: 60%;
    margin: 0 auto;

    @include respond(tab-port) {
        width: 80%;
    }

    @include respond(phone) {
        width: 100%;
    margin-bottom: 10rem;

    }

}

.finish__btn {
    background: $color-rename;
    // padding: 1rem 2rem;
    position: fixed;
    bottom: 3rem;
    right: 3rem;
    border-radius: 50%;
    width: 6rem;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 5px rgba($color-dark, .5);

    .icon_checkmark {
        width: 3rem;
        height: 3rem;
    }
}

.overlay {
    background: rgba($color-dark, .5);
}