.form {
    display: flex;
    margin-bottom: 3rem;
}

.form__input {
    width: 100%;
    // min-width: 70%;
    height: 3.5rem;
    padding: .5rem;
    outline: none;
    border-radius: none;
}