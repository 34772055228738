// MEDIA QUERY MANAGER

/*

0 - 600px:      Phone

600 - 900px:    Tablet Portrait

900 - 1200px:   Tablet Landscape

1200 - 1800px:  Standard Desktop size

1800px + :      Big Desktop


$breakpoint argument choices:
-   phone
-   tab-port
-   tab-land
-   big-desktop

1em = 16px

example 

@include respond(phone) {
            top: 3.5rem;
            right: 3.5rem;
        }

        
*/

@mixin respond($breakpoint) {
    @if $breakpoint == phone {
        @media (max-width: 37.5em) {@content}; // 600px
    }

    @if $breakpoint == tab-port {
        @media (max-width: 56.25em) {@content}; // 900px
    }

    @if $breakpoint == tab-land {
        @media (max-width: 75em) {@content}; // 1200px
    }

    @if $breakpoint == big-desktop {
        @media (min-width: 112.5em) {@content}; // 1800px
    }
}