.favourites {
    position: absolute;
    left: 0;
    z-index: 999;
    overflow: hidden;
    margin-top: 3px;
    top: 100%;
    width: 100%;
    height: 0;
}

.favourites__ul {
    // position: absolute;
    // z-index: 9999;
    opacity: 0;
    transform: translateY(-100%);
    transition: all .7s;
    animation: rollUp .7s;
    width: 100%;
}

.show-favourites {
    .favourites__ul {
        opacity: 1;
    animation: rollDown .7s;
    transform: translateY(0);
    }

}

.favourite {
    min-width: 34rem;
    color: $color-dark;
    list-style: none;
    border: 1px solid $color-mid;
    border-radius: 0 3px 3px 3px;
    opacity: 1;
    padding: 1.5rem 3rem;
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    cursor: pointer;
    background-color: $color-light;


    &:not(:first-child) {
        margin-top: -1px;
    }

    &:last-child {
        border-radius: 0 0 3px 3px;
    }

    p {
        pointer-events: none;
    }
}

@keyframes rollDown {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }

    5% {
        opacity: 1;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes rollUp {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    98% {
        opacity: .5;
    }

    100% {
        opacity: 0;
        transform: translateY(-100%);
    }
}

.addFavourite {
    fill: gold;

    &:active {
        transform: scale(0.9);;
    }
}

.added {
    animation: added .2s ease-in-out;
}

@keyframes added {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }

}
