.icon {
    width: 1.5rem;
    height: 1.5rem;
    fill: $color-light;
}

.btn__star {
    padding: 1rem 1.5rem;
}

.icon_star {
    fill: transparent;
    stroke: $color-dark;
}